import styled from 'styled-components';
import { cloneElement } from 'react';

const Container = styled.form`
  max-width: ${p => p.width};
  > * {
    margin-bottom: 10px;
    :last-child {
      margin-bottom: 0px;
    }
  }
`;

const Form = ({ children, onSubmit, canSubmit, width, style, className }) => {
  const handleKeyDown = e => {
    if (e.key === 'Enter' && canSubmit) {
      onSubmit();
      e.preventDefault();
    }
  };

  if (Array.isArray(children)) {
    return (
      <Container onSubmit={onSubmit} width={width} style={style}>
        {children.map((child, i) =>
          cloneElement(child, {
            key: i,
            onKeyDown: handleKeyDown,
          })
        )}
      </Container>
    );
  }

  return (
    <Container onSubmit={onSubmit} style={style} className={className}>
      {cloneElement(children, {
        onKeyDown: handleKeyDown,
      })}
    </Container>
  );
};

Form.defaultProps = {
  onSubmit: () => {},
  canSubmit: true,
  width: '100%',
};

export default Form;
